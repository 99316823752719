import React from 'react';
import '../css/articulos.css';
import tesis1 from '../images/tesis1.png';
import tesis2 from '../images/tesis2.png';
import pdf1 from '../recursos/tesis2.pdf';
import pdf2 from '../recursos/tesis1.pdf';

const Articulos = () => {
    let movil = 0;
    if(typeof window.orientation !== 'undefined') {
        movil = 1;
    }
    return(
        <section data-scroll-section className="col s12 m12 l12 center-align" style={{padding: '3rem 0 7rem 0'}} id="tesis">
            <h1 style={{color: 'rgba(0,0,0,.7)'}} data-scroll data-scroll-speed="1">Tesis Doctorales</h1>
            <div className="col s12 m12 l12">
                <div className="col s12 m6 l6 i_tes" style={{borderRight: '2px solid rgba(0,0,0,0.2)', height: '100%'}}>
                    <img src={tesis1} alt="tesis1" className="responsive-img" style={{pointerEvents: 'none'}}/>
                    <div className="col s12 m12 l12 los_arts">
                    {movil === 0 ? <a href="./pdf1" target="_blank" className="waves-effect waves-light btn-large blue-grey darken-3" style={{marginTop: '1rem'}}><i className="material-icons left">remove_red_eye</i>Ver</a>
                     :
                     <a href={pdf1} className="waves-effect waves-light btn-large blue-grey darken-3" style={{marginTop: '1rem'}}><i className="material-icons left">file_download</i>Descargar</a>
                    }
                    </div>
                </div>

                <div className="col s12 m6 l6">
                    <img src={tesis2} alt="tesis2" className="responsive-img" style={{pointerEvents: 'none'}}/>
                    <div className="col s12 m12 l12 los_arts">
                        {movil === 0 ? <a href="./pdf2" target="_blank" className="waves-effect waves-light btn-large blue-grey darken-3" style={{marginTop: '1rem'}}><i className="material-icons left">remove_red_eye</i>Ver</a>
                        :
                            <a href={pdf2} className="waves-effect waves-light btn-large blue-grey darken-3" style={{marginTop: '1rem'}}><i className="material-icons left">file_download</i>Descargar</a>
                        }
                        
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Articulos;