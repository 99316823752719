import React from 'react';
import pdf2 from '../recursos/tesis1.pdf';

const Pdf2 = () => {
    const preloadIn = document.body;
    preloadIn.classList.remove('loading');
    preloadIn.style.background = '#fff';
    preloadIn.style.color = '#1d1d1d';
    return (
        <div style={{position: 'absolute', width: '100%', height: '100%'}}>
            <object
                data={pdf2}
                type="application/pdf"
                width="100%"
                height="100%"
            >
            </object>
        </div>
    );
}

export default Pdf2;