import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import App from './App';
import Pdf from './components/Pdf';
import Pdf1 from './components/Pdf1';
import Pdf2 from './components/Pdf2';

const Router = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/" component={App} />
                <Route exact path="/pdf" component={Pdf} />
                <Route exact path="/pdf1" component={Pdf1} />
                <Route exact path="/pdf2" component={Pdf2} />
            </Switch>
        </BrowserRouter>
    );
}

export default Router;