import React from 'react';

import '../css/footer.css';
import face from '../images/facebook_btn.png';
import linke from '../images/link_btn.png';

function Footer (){

    const goLink = link => {
        if(link === 1){//Ir pagina facebook
            window.open('https://www.facebook.com/romanmassomex', '_blank');
        } 
        if(link === 2){//Ir linkedin
            window.open('https://mx.linkedin.com/in/romanmasso', '_blank');
        } 
    }

    return(
        <section data-scroll-section className="col s12 m12 l12 center-align" id="el_foot">
            <div className="col s12 m12 l12">
                <img src={face} style={{marginRight: '5%'}} onClick={ ()=> goLink(1)} alt="facelogo"/>
                <img src={linke} onClick={ ()=> goLink(2)} alt="linkelogo"/>
            </div>
           <p data-scroll data-scroll-speed="1">&copy; 2022 Dr. Alfredo Ismael Román Masso</p>
        </section>
    );
}

export default Footer;