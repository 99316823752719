import React from 'react';
import pdf1 from '../recursos/tesis2.pdf';

const Pdf1 = () => {
    const preloadIn = document.body;
    preloadIn.classList.remove('loading');
    preloadIn.style.background = '#fff';
    preloadIn.style.color = '#1d1d1d';
    return (
        <div style={{position: 'absolute', width: '100%', height: '100%'}}>
            <object
                data={pdf1}
                type="application/pdf"
                width="100%"
                height="100%"
            >
            </object>
        </div>
    );
}

export default Pdf1;