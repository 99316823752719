import React from 'react';
import img from './../images/foto.png'; 
import maroma from './../images/maroma.jpg';
import electromart from './../images/electromart.png';
import trescompras from './../images/trescompras.png';
import logomail from './../images/logo_mail.png'; 
import '../css/header.css';

const Header = () => {
    
    const goLink = link => {
        if(link === 1){//Ir Electromart
            window.open('https://electromart.mx', '_blank');
        } 
        if(link === 2){//Ir 3Compras
            window.open('https://3compras.com', '_blank');
        } 
        if(link === 3){//Ir Maroma
            window.open('https://www.facebook.com/FUNDACIONMAROMA', '_blank');
        } 

    }
    return (
            <div data-scroll-section id="cabecera1" className="col s12 m12 l12">
                    <div className="col s12 m5 l6">
                        <img className="responsive-img" src={img} alt="Roman.jpg" id="main_photo"/>
                    </div>
                    <div className="col s12 m7 l6">
                        <div id="main">
                                <h1 style={{marginBottom: '5px'}}>Dr. Alfredo Ismael Román Masso</h1>
                                <h5 style={{margin: '0px'}}>Ácademico y emprendedor</h5>
                                <div className="col s12 m12 l12" id="los-logos">
                                    <div className="content-logos">
                                        <img id="electro" className="responsive-img pulse" src={electromart} alt="electromart.png" style={{cursor: "pointer"}} onClick={ ()=> goLink(1)}/>
                                        <img id="trescom" className="responsive-img tossing" src={trescompras} alt="trescompras.png" style={{cursor: "pointer"}} onClick={ ()=> goLink(2)}/>
                                    </div>
                                    <div className="content-logos">
                                        <img id="maroma" className="responsive-img floating" src={maroma} alt="maroma.jpg" style={{cursor: "pointer"}} onClick={ ()=> goLink(3)}/>
                                    </div>
                                </div>
                                <div className="col s12 m12 l12" id="contacto">
                                    <img 
                                        className="responsive-img"
                                        src={logomail}
                                        alt="email"
                                    />
                                    <div>
                                        <h5 style={{color: '#212121', marginTop: '2px'}}>romanmasso@gmail.com</h5>
                                        <h5 style={{color: '#212121', marginTop: '2px'}}>romanmasso@electromart.mx</h5>
                                    </div>
                                </div>
                        </div>
                    </div>
            </div>

    );
}
export default Header;