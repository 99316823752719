import React from 'react';
import yo from '../images/yo.png';
import '../css/yo.css';

const Yo = () => {
    return(
        <section data-scroll-section className="col s12 m12 l12 pp" id="yo">
            <div className="col s12 m12 l12 center-align">
                <h1 data-scroll data-scroll-speed="1">Quien Soy</h1>
                <div className="col s12 m6 l6">
                    <img src={yo} alt="Yo" className="responsive-img" style={{borderRadius: '10px', pointerEvents: 'none'}}/>
                </div>
                <div className="col s12 m6 l6" id="cte_p">
                    <p>¡Hola! Mi nombre es Alfredo Ismael Román Masso, soy un emprendedor y académico de la Ciudad de México, me especializo en las micro, pequeñas y medianas empresas, por mucho tiempo me he dedicado a la venta y distribución de car audio y electrónica.</p>
                    <br />
                    <p>Actualmente me encuentro impulsando diferentes Startup del mismo ramo, además de participar en un proyecto Anáhuac-IPN que revolucionará el e-commerce en México. También comparto una gran pasión por el altruismo y el bienestar social.</p>
                </div>
            </div>
        </section>
    );
}

export default Yo;