import React from 'react';
import '../css/navbar.css';

const Navbar = () => {
    return(
        <div data-scroll-section className="col s12 m12 l12 cont">
                <a href="#yo" data-scroll-to><button className="button button--mimas"><span><span>Quien soy</span></span></button></a>
                <a href="#curriculum" data-scroll-to><button className="button button--mimas"><span><span>Curriculum</span></span></button></a>
                <a href="#tesis" data-scroll-to><button className="button button--mimas"><span><span>Tesis doctorales</span></span></button></a>
                <a href="#galeria" data-scroll-to><button className="button button--mimas"><span><span>Galería</span></span></button></a>
                <a href="#videos" data-scroll-to><button className="button button--mimas"><span><span>Videos</span></span></button></a>
        </div>
    );
}

export default Navbar;