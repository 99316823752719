import React from 'react';

import Background30 from '../images/demo1/30.jpg';
import Background29 from '../images/demo1/29.jpg';
import Background28 from '../images/demo1/28.jpg';
import Background27 from '../images/demo1/27.jpg';
import Background26 from '../images/demo1/26.jpg';
import Background25 from '../images/demo1/25.jpg';
import Background24 from '../images/demo1/24.jpg';
import Background23 from '../images/demo1/23.jpg';
import Background22 from '../images/demo1/22.jpg';
import Background21 from '../images/demo1/21.jpg';
import Background20 from '../images/demo1/20.jpg';
import Background19 from '../images/demo1/19.jpg';
import Background18 from '../images/demo1/18.jpg';

import '../css/galeria.css';

const Galeria = () => {
    return(
        <div data-scroll-section style={{backgroundColor: '#111', padding: '0 0 5rem 0'}} className="col s12 m12 l12 center-align" id="galeria">
			<h1 data-scroll data-scroll-speed="1">Galería</h1> 
            <section className="col s12 m12 l12 tiles" id="grid">
				<div className="tiles__wrap">
					<div className="tiles__line" data-scroll data-scroll-speed="1" data-scroll-target="#grid" data-scroll-direction="horizontal">
						<div className="tiles__line-img" style={{backgroundImage: "url(" + Background30 + ")"}}></div>
						<div className="tiles__line-img" style={{backgroundImage: "url(" + Background29 + ")"}}></div>
						<div className="tiles__line-img" style={{backgroundImage: "url(" + Background28 + ")"}}></div>
						<div className="tiles__line-img" style={{backgroundImage: "url(" + Background27 + ")"}}></div>
						<div className="tiles__line-img" style={{backgroundImage: "url(" + Background26 + ")"}}></div>
						<div className="tiles__line-img" style={{backgroundImage: "url(" + Background30 + ")"}}></div>
					</div>
					<div className="tiles__line" data-scroll data-scroll-speed="2" data-scroll-target="#grid" data-scroll-direction="horizontal">
						<div className="tiles__line-img" style={{backgroundImage: "url(" + Background30 + ")"}}></div>
						<div className="tiles__line-img" style={{backgroundImage: "url(" + Background25 + ")"}}></div>
						<div className="tiles__line-img" style={{backgroundImage: "url(" + Background24 + ")"}}></div>
						<div className="tiles__line-img" style={{backgroundImage: "url(" + Background23 + ")"}}></div>
						<div className="tiles__line-img" style={{backgroundImage: "url(" + Background22 + ")"}}></div>
						<div className="tiles__line-img" style={{backgroundImage: "url(" + Background30 + ")"}}></div>
					</div>
					<div className="tiles__line" data-scroll data-scroll-speed="1" data-scroll-target="#grid" data-scroll-direction="horizontal">
						<div className="tiles__line-img" style={{backgroundImage: "url(" + Background30 + ")"}}></div>
						<div className="tiles__line-img" style={{backgroundImage: "url(" + Background21 + ")"}}></div>
						<div className="tiles__line-img" style={{backgroundImage: "url(" + Background20 + ")"}}></div>
						<div className="tiles__line-img" style={{backgroundImage: "url(" + Background19 + ")"}}></div>
						<div className="tiles__line-img" style={{backgroundImage: "url(" + Background18 + ")"}}></div>
						<div className="tiles__line-img" style={{backgroundImage: "url(" + Background30 + ")"}}></div>
					</div>
				</div>
			</section>
        </div>
    );
}

export default Galeria;