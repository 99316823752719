import React from 'react';
import videocv from '../recursos/cv_video.mp4';
import pdf from '../recursos/cv_roman.pdf';
import cvp1 from '../recursos/cv_p1.png';
import cvp2 from '../recursos/cv_p2.png';
import cvp3 from '../recursos/cv_p3.png';
import '../css/curriculum.css';

const Curriculum = () => {
    let movil = 0;
    if(typeof window.orientation !== 'undefined') {
        movil = 1;
    }
    return(
        <section data-scroll-section className="col s12 m12 l12" style={{padding: '3rem 0 7rem 0'}} id="curriculum">
            <div className="col s12 m12 l12 center-align">
                <h1 style={{color: 'rgba(0,0,0,.7)'}} data-scroll data-scroll-speed="1">Curriculum</h1>
                <div className="col s12 m6 l6">
                    <video className="responsive-video" controls>
                        <source src={videocv} type="video/mp4" />
                    </video>
                </div>
                <div className="col s12 m6 l6" id="desc_cvs">
                    <div className="col s12 m12 l12" id="content_cvs">
                        <img src={cvp1} alt="cvp1"/>
                        <img src={cvp2} alt="cvp1"/>
                        <img src={cvp3} alt="cvp1"/>
                    </div>
                    {movil === 0 ? <a href="./pdf" target="_blank" ><button className="button button--janus"><span>Ver</span></button></a>
                     :
                        <a href={pdf} download="cv_romanmasso.pdf"><button className="button button--janus"><span>Descargar</span></button></a>
                    }
                    
                </div>
            </div>
        </section>
    )
}

export default Curriculum;