import React, { useRef } from 'react';

const Videos = () => {
    const video = useRef();
    const video2 = useRef();
    const video3 = useRef();
    const video4 = useRef();
    const video5 = useRef();
    const video6 = useRef();
    const video7 = useRef();
    let flag = 0, prevlink=null, prevframe=null;

    const play = (link, what) => {
        const frame = what.current;
        if(prevlink === null) {
            //Si va a reproducir por primera vez el video
            prevlink = link;
            prevframe = frame;
        } else {
            if(prevlink === link) {
                //Si esta en el mismo video y hace stop o quiere volver a reproducir el mismo
                prevlink = link;
                prevframe = frame;
            } else {
                //Si le da reproducir a otro video:
                prevframe.parentNode.childNodes[1].classList.remove("mirror-pasive");
                prevframe.parentNode.childNodes[1].classList.add("mirror-active");
                prevframe.src = prevlink;
                flag = 0;
                prevlink = link;
                prevframe = frame;
            }
        }
        frame.parentNode.childNodes[1].classList.remove("mirror-active");
        frame.parentNode.childNodes[1].classList.add("mirror-pasive");
        if(flag === 0){
            frame.src += "?autoplay=1";
            flag = 1; 
        }else {
            frame.parentNode.childNodes[1].classList.remove("mirror-pasive");
            frame.parentNode.childNodes[1].classList.add("mirror-active");
            frame.src = link;
            flag = 0;
        }
    }
    return(
        <div data-scroll-section className="col s12 m12 l12 center-align pp" id="videos">
            <h1 data-scroll data-scroll-speed="1">Videos</h1>
            <div className="col s12 m12 l12">
                <div data-scroll data-scroll-speed="1" className="col s12 m6 l6" style={{margin: '2rem 0'}}>
                    <h3>Identificando oportunidades de negocio</h3>
                    <div className="video-container">
                        <iframe
                            ref={video7}
                            title="Identificando oportunidades de negocio"
                            src="https://www.youtube.com/embed/E55vNxg1pPM"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                        <div onClick={() => play('https://www.youtube.com/embed/E55vNxg1pPM',video7)} className="mirror-active"></div>
                    </div>
                </div>
                <div data-scroll data-scroll-speed="1" className="col s12 m6 l6" style={{margin: '2rem 0'}}>
                    <h3>Resiliencia en los negocios</h3>
                    <div className="video-container">
                        <iframe
                            ref={video}
                            title="Resiliencia en los negocios"
                            src="https://www.youtube.com/embed/aUPtCTMqeiY"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                        <div onClick={() => play('https://www.youtube.com/embed/aUPtCTMqeiY',video)} className="mirror-active"></div>
                    </div>
                </div>
                <div className="col s12 m6 l6" style={{margin: '2rem 0'}}>
                    <h3>Emprender y no morir en el intento</h3>
                    <div className="video-container">
                        <iframe
                            ref={video2}
                            title="Emprender y no morir en el intento"
                            id="video"
                            src="https://www.youtube.com/embed/aFa_HOcf1Ug"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                        <div onClick={() => play('https://www.youtube.com/embed/aFa_HOcf1Ug',video2)} className="mirror-active"></div>
                    </div>
                </div>
                <div className="col s12 m6 l6" style={{margin: '2rem 0'}}>
                    <h3>Examen Doctoral Alfredo Ismael Román Masso</h3>
                    <div className="video-container">
                        <iframe
                            ref={video3}
                            title="Examen Doctoral Alfredo Ismael Román Masso"
                            id="video"
                            src="https://www.youtube.com/embed/0QRnaSlIFyM"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                        <div onClick={() => play('https://www.youtube.com/embed/0QRnaSlIFyM',video3)} className="mirror-active"></div>
                    </div>
                </div>
                <div data-scroll data-scroll-speed="1" className="col s12 m6 l6" style={{margin: '2rem 0'}}>
                    <h3>Quik 2019</h3>
                    <div className="video-container">
                        <iframe
                            ref={video4}
                            title="Quik 2019"
                            id="video"
                            src="https://www.youtube.com/embed/-VE5fObKs_8"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                        <div onClick={() => play('https://www.youtube.com/embed/-VE5fObKs_8',video4)} className="mirror-active"></div>
                    </div>
                </div>
                <div data-scroll data-scroll-speed="1" className="col s12 m6 l6" style={{margin: '2rem 0'}}>
                    <h3>Spot a candidato diputado Constituyente 2017</h3>
                    <div className="video-container">
                        <iframe
                            ref={video5}
                            title="Spot a candidato diputado Constituyente 2017"
                            id="video"
                            src="https://www.youtube.com/embed/i2qH-qjNZkw"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                        <div onClick={() => play('https://www.youtube.com/embed/i2qH-qjNZkw',video5)} className="mirror-active"></div>
                    </div>
                </div>
                <div className="col s12 m6 l6" style={{margin: '2rem 0'}}>
                    <h3>Inversión de mercados publicos: Basureros</h3>
                    <div className="video-container">
                        <iframe
                            ref={video6}
                            title="Inversión de mercados publicos: Basureros"
                            id="video"
                            src="https://www.youtube.com/embed/S4dnmF_r5jk"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                        <div onClick={() => play('https://www.youtube.com/embed/S4dnmF_r5jk',video6)} className="mirror-active"></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Videos;