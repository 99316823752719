import React from 'react';

import Header from './Header';
import Navbar from './Navbar';
import Yo from './Yo';
import Curriculum from './Curriculum';
import Articulos from './Articulos';
import Galeria from './Galeria';
import Videos from './Videos';
import Footer from './Footer';

const Miapp = () => {
    return(
        <>
            <Header />
            <Navbar />
            <Yo />
            <Curriculum />
            <Galeria />
            <Articulos />
            <Videos />
            <Footer />
        </>
    )
}

export default Miapp;