import React, { useRef } from 'react';
import './App.css';

import Miapp from './components/Miapp';

//Importando el provider de Locomotive
import { LocomotiveScrollProvider } from 'react-locomotive-scroll'

function App(){

    const containerRef = useRef(null);

    //Opciones de instancia locomotive:
    const opciones = {
        smooth: true,
        smartphone: {
            smooth: true
        },
        tablet: {
            smooth: false
        }
    };
    //Dependencias al hacer scroll
    const watch = [];

    const preloadIn = document.body;
    setTimeout(() => {
        preloadIn.classList.remove('loading');
        preloadIn.style.background = '#fff';
        preloadIn.style.color = '#1d1d1d';
    }, 3500);
    return (
        <LocomotiveScrollProvider
            options={opciones}
            watch={watch}
            containerRef={containerRef}
        >
            <main data-scroll-container ref={containerRef} style={{background: '#eee'}} className="row">
                <Miapp />
            </main>
        </LocomotiveScrollProvider>
    );
}

export default App;

